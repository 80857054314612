<template>
  <v-app>
    <v-container style="text-align: center !important;">
      <div class="mb-5">
        <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo" />
        <h2>Create New Password</h2>
      </div>
      <v-alert v-if="error" type="error" color="red">{{ errorMessage }}</v-alert>
      <v-alert v-if="success" type="success" color="green">{{ successMessage }}</v-alert>
      <v-form ref="form" @submit.prevent="submitForm" class="mb-5">
        <v-text-field
          v-model="formData.newPassword"
          label="New Password"
          :type="showPassword ? 'text' : 'password'"
          required
          :rules="passwordRules"
          append-icon="mdi-eye"
          @click:append="togglePasswordVisibility"
        ></v-text-field>
        <v-text-field
          v-model="formData.confirmPassword"
          label="Confirm Password"
          :type="showPassword ? 'text' : 'password'"
          required
          :rules="confirmPasswordRules"
          append-icon="mdi-eye"
          @click:append="togglePasswordVisibility"
        ></v-text-field>
        <v-btn type="submit" :disabled="submitting" class="primary">
          <template v-if="submitting">Creating new password...</template>
          <template v-else>Create Password</template>
        </v-btn><br style="margin-top: 60px !important;"/><br/><br/><br/>
        <v-btn to="/login">Go to Login</v-btn>
      </v-form>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        email: '',
        newPassword: '',
        confirmPassword: '',
        token: '',
        userId: ''
      },
      error: false,
      success: false,
      successMessage: '',
      errorMessage: '',
      showPassword: false,
      submitting: false
    }
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'Email is required',
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid email format'
      ]
    },
    passwordRules() {
      return [
        (v) => !!v || 'Password is required',
        (v) => v.length >= 8 || 'Password must be at least 8 characters'
      ]
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || 'Confirm Password is required',
        (v) => v === this.formData.newPassword || 'Passwords do not match'
      ]
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    validateForm() {
      if (this.$refs.form) {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          this.error = true
          this.errorMessage = 'Please fix the form errors.'
        }

        return isValid
      } else {
        console.error('Form reference not found.')

        return false
      }
    },
    async submitForm() {
      this.error = false
      if (this.validateForm()) {
        try {
          this.submitting = true
          // Make the password reset action call
          const response = await this.$store.dispatch('auth/resetPassword', {
            newPassword: this.formData.newPassword,
            confirmPassword: this.formData.confirmPassword,
            resetToken: this.formData.token,
            userId: this.formData.userId
          })

          if (response && response.error) {
            // Handle error response
            this.error = true
            this.errorMessage = response.error
          } else {
            this.success = true
            this.successMessage = response.message
            this.error = false

            // Redirect after 5 seconds
            setTimeout(() => {
              // Use Vue Router for client-side navigation
              this.$router.push({ path: 'password-reset-success', query: { email: response.email } })
            }, 5000) // 5000 milliseconds = 5 seconds
          }

        } catch (error) {
          console.error('Error during password reset:', error)
          // Handle password reset error if needed
        } finally {
          this.submitting = false
        }
      }
    }
  },
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    
    if (urlSearchParams.get('token')) {
      this.formData.token = urlSearchParams.get('token')
      this.formData.userId = urlSearchParams.get('userId')
    } else {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
/* Add your custom styles here */

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

body {
  background-color: white !important;
}
</style>
